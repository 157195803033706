<template>
  <aside
    class="left-sidebar"
    :class="
      Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`
    "
  >
    <div :class="`bg-${logoColor} navbar-header`">
      <!-- <LogoDark v-if="logoColor == 'white'" />
      <LogoLight v-else /> -->
       <b-navbar-brand >
        <b class="logo-icon">
          <router-link to="/dashboard_new"> 
          <!-- <img src="../../../assets/images/venue_booking/logo.svg" alt="homepage" class="w-75 mx-auto" >  --> 
          <img :src="loadImage" alt="homepage" class="w-75 mx-auto" >  
          </router-link>
          </b>
       </b-navbar-brand>
      <span
        class="d-block d-lg-none close-sidebar-btn"
        @click="showMobileSidebar"
        ><i class="ti ti-close"></i></span>
    </div>
    <VuePerfectScrollbar class="scrlbar">
      <ul class="nav flex-column mb-0">
        <template v-for="(route, i) in routes">
          <!---Menu Title -->
          <li class="nav-item" :key="route.header" v-if="route.header">
            <div class="d-flex nav-small-cap">
              <!-- <i :class="route.icon"></i> -->
              <span class="hide-text">{{ route.header }}</span>
            </div>
          </li>
          <!---Sub Menu -->
          <li class="nav-item" :key="route.title" v-if="route.childern">
            <a class="nav-link d-flex" v-b-toggle="route.id">
             <!--  <feather :type="route.icon"></feather> -->
              <span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
              <i class="ti ti-angle-down dd-arrow ml-auto hide-text"></i>
            </a>
            <b-collapse
              :id="route.id"
              accordion="sidebar-accordion"
              :visible="
                $route.path ==
                `${route.name + $route.name.replace(/\s+/g, '-').toLowerCase()}`
              "
            >
              <ul class="nav flex-column sub-menu">
                <template v-for="(subroute,i) in route.childern" >
                  <li class="nav-item" :key="i">
                    <router-link class="nav-link d-flex" :to="`${subroute.to}`">
                     <!--  <i :class="subroute.icon"></i> -->
                      <span class="hide-text">{{ subroute.title }}</span>
                    </router-link>
                  </li>
                </template>
              </ul>
            </b-collapse>
          </li>
          <!---Single Menu -->
          <li class="nav-item" :key="i" v-if="!route.childern && !route.header">
            <router-link :to="`${route.to}`" class="nav-link d-flex">
             <!--  <feather :type="route.icon"></feather> -->
              <span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </VuePerfectScrollbar>
<!--     {{ routes }} -->
  </aside>
</template>

<script>
// import LogoDark from "../logo-dark/LogoDark";
// import LogoLight from "../logo-light/LogoLight";
import { mapState } from "vuex";
//import SidebarData from "./SidebarData";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from 'axios';
export default {
  name: "VerticalSidebar",
  components: {
    // LogoDark,
    // LogoLight,
    VuePerfectScrollbar,
  },
  data: () => ({
    role_type: JSON.parse(localStorage.getItem("loggedUserData")).role_type,
    initialShow: true,
    routes: [],
    routers: [],
    logo:require('../../../assets/images/venue_booking/logo.svg'),
    loadImage:require('../../../assets/images/venue_booking/logo.svg'),
    
    // routes: SidebarData,
  }),
  computed: {
    ...mapState(["logoColor", "Sidebar_drawer", "SidebarColor"]),
    check() {
      return console.log(this.$router.history.current);
    },
  },
  methods: {
    showMobileSidebar() {
      this.$store.commit("SET_SIDEBAR_DRAWER", false);
    },
    get_profile()
    {
  
      axios.get("/admin/get_profile").then((resp) => {
      
					if (resp.data.status_code == 200) {
            if(resp.data.user_details.logo)
            {
             
              this.logo= process.env.VUE_APP_APIURL+'/'+resp.data.user_details.logo;
              this.loadImage = process.env.VUE_APP_APIURL+'/'+resp.data.user_details.logo;
              localStorage.setItem('Logo',this.logo);
            }
            this.$store.commit("ALL_USER_DATA", resp.data.user_details);
						
					}
         
				}) .catch((error) => { 
               if (error) {
                this.$root.$refs.app.showToast("danger", 'Unauthenticated 401', "Error");
                localStorage.clear();
               }  
          })
    },
    get_modules()
    {
      axios.get("/admin/Modules").then((resp) => {
					if (resp.data.status_code == 200) {
            for(var i=0;i<resp.data.modules.length;i++)
            {
                var childdren=[];
                for(var j=0; j< resp.data.modules[i].children.length;j++)
                {
                  childdren.push({
                      icon:resp.data.modules[i].children[j].sub_icon,
                      title: resp.data.modules[i].children[j].subname,
                        to: resp.data.modules[i].children[j].url,
                    })
                }
                if(resp.data.modules[i].children.length>1)
                {
                  if( resp.data.modules[i].name!='')
                  {
                  this.routes.push(
                  {
                      id: '20'+resp.data.modules[i].Mid,
                      icon:resp.data.modules[i].icon,
                      title: resp.data.modules[i].name,
                      i18n: resp.data.modules[i].i18n,
                      childern:childdren
                  })
                }
                }
                else
                {
                  if( resp.data.modules[i].name!='')
                  {
                  this.routes.push(
                  {
                      id: '20'+resp.data.modules[i].Mid,
                      icon:resp.data.modules[i].icon,
                      title: resp.data.modules[i].name,
                      i18n: resp.data.modules[i].i18n,
                      to: resp.data.modules[i].url,
                  })
                }
                }
             }
					}
				});
    }


  },
  mounted() {
 //   this.get_modules();
    this.get_profile();
    this.get_modules();
    this.loadImage = localStorage.getItem('Logo');

    /* 

    if (this.role_type == "admin") {
      this.routes = [
        {
          id: "1",
          icon: "crosshair",
          title: "Dashboard",
          i18n: "Dashboard",
          to: "/dashboard",
        },
      
        // {
        //   id: "2",
        //   icon: "briefcase",
        //   title: "Manage Franchise",
        //   i18n: "Manage Franchise",
        //   to: "/manage_franchise",
        // },
        
        {
          id: "2",
          icon: "users",
          title: "Vendors",
          i18n: " Vendors",
          name: "/Vendors/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "View Vendors",
                to: "/Manage_vendors",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Manage Vendors",
                to: "/admin_manage_booking",
            },
          
          ],
        },
        // {
        //   id: "4",
        //   icon: "users",
        //   title: "Manage Staff",
        //   i18n: "Manage staff",
        //   to: "/manage_staff",
        // },
        {
          id: "3",
          icon: "book",
          title: "Bookings",
          i18n: " Bookings",
          name: "/Bookings/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "Create Booking",
                to: "/Create_booking",
            }, 
            {
              icon: "mdi mdi-arrow-right",
              title: "View Booking",
                to: "/all_booking",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Manage Booking",
                to: "/Booking/manage_booking",
            }, 
            {
              icon: "mdi mdi-arrow-right",
              title: "Draft Booking",
                to: "/Booking/Draft_booking",
            }, 
             {
              icon: "mdi mdi-arrow-right",
              title: "Historical",
                to: "/historical_booking",
            },
          
          ],
        },
        {
          id: "4",
          icon: "users",
          title: "Customers",
          i18n: " Customers",
          name: "/my-customers/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "View Customer",
                to: "/Customer/view_customer",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Manage Customer",
                to: "/Customer/manage_customer",
            },
          
          ],
        },
        {
          id: "5",
          icon: "calendar",
          title: "Calendar",
          i18n: "Calendar",
          to: "/calendar",
        },
        // {
        //   id: "8",
        //   icon: "bar-chart",
        //   title: "Statistics",
        //   i18n: "Statistics",
        //   to: "/statistic",
        // },
        // {
        //   id: "9",
        //   icon: "copy",
        //   title: "Reports",
        //   i18n: "Reports",
        //   to: "/reports",
        // },
        // {
        //   id: "10",
        //   icon: "credit-card",
        //   title: "Wallet",
        //   i18n: "Wallet",
        //   to: "/wallet",
        // },
        // {
        //   id: "11",
        //   icon: "tag",
        //   title: "Coupons & Discounts",
        //   i18n: "Coupons & Discounts",
        //   to: "/coupons_discounts",
        // },
        {
          id: "6",
          icon: "plus-square",
          title: "Upload",
          i18n: " Upload",
          name: "/Admin Setting/",
          childern: [
         
            {
              icon: "mdi mdi-arrow-right",
              title: "Pincode Upload",
                to: "/Upload/pincode_manage",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "History Upload",
                to: "/Upload/history_upload",
            },
          
          ],
        },
      
        {
          id: "8",
          icon: "dollar-sign",
          title: " Finance",
          i18n: " Finance",
          name: "/Finance/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "Invoices/Payments",
                to: "/Admin/finance_module",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Taxation",
                to: "/Admin/finance_taxation",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Statement/Reports",
                to: "/Admin/satement_report",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Settings",
                to: "/Admin/finance_setting",
            },
          ],
        },
        {
          id: "9",
          icon: "users",
          title: "Service provider",
          i18n: " Service provider",
          name: "/service provider/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "View Service Provider",
                to: "/service_provider",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Manage Service Provider",
                to: "/manage_provider",
            },
          
          ],
        },
        {
          id: "10",
          icon: "users",
          title: "Franchise",
          i18n: " Franchise",
          name: "/Franchise/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "View Franchise",
                to: "/view_franchise",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Manage Franchise",
                to: "/Manage_franchise",
            },
          
          ],
        },
        {
          id: "11",
          icon: "settings",
          title: "Security",
          i18n: " Security",
          name: "/my-customers/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "Modules",
                to: "/manage_vendors",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Permission",
                to: "/admin_manage_booking",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Roles",
                to: "/admin_manage_booking",
            },
          
          ],
        },
       
        
        // {
        //   id: "13",
        //   icon: "chrome",
        //   title: "Manage Venues",
        //   i18n: "Manage Venues",
        //   to: "/admin-venues",
        // },
        // {
        //   id: "14",
        //   icon: "alert-triangle",
        //   title: "Manage Issue",
        //   i18n: "Manage Issue",
        //   to: "/manage_issues",
        // },
      ];
    } else if (this.role_type == "vendor") {
      this.routes = [
       
        {
          id: "15",
          icon: "crosshair",
          title: "Dashboard",
          i18n: "Dashboard",
          to: "/dashboard_new",
        },
        {
          id: "2",
          icon: "book",
          title: " Venues",
          i18n: " Venues",
          name: "/Venues/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "Create venue",
                to: "/venues/add-parent-child-venue",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Manage Venue",
                to: "/venues",
            },
          ],
        },
        {
          id: "3",
          icon: "chrome",
          title: " Bookings",
          i18n: " Bookings",
          name: "/Bookings/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "Create Booking",
                to: "/add-booking",
            },
            
            {
              icon: "mdi mdi-arrow-right ",
              title: "View Booking",
                to: "/my-booking",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Manage Booking",
                to: "/statements",
            },
             {
              icon: "mdi mdi-arrow-right",
              title: "Draft Booking",
                to: "/drafts-booking",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Historical",
                to: "/historical_tab",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Customers",
                to: "/my-customers",
            },
          ],
        },
        {
          id: "4",
          icon: "calendar",
          title: "Calendar",
          i18n: "Calendar",
          to: "/new_calender",
        },
        {
          id: "5",
          icon: "settings",
          title: "Venue Settings",
          i18n: "Venue Settings",
          to: "/child_venue_setting/0",
        },
        {
          id: "7",
          icon: "dollar-sign",
          title: " Finance",
          i18n: " Finance",
          name: "/Finance/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "Invoices/Payments",
                to: "/finance_modules",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Income/Expenses",
                to: "/finance/income-expense",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Taxation",
                to: "/finance/taxation",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Statement/Reports",
                to: "/finance/satement_report",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Settings",
                to: "/finance_settings",
            },
          ],
        },
         {
          id: "8",
          icon: "package",
          title: " Service Provider",
          i18n: " Service Provider",
          name: "/Finance/",
          childern: [
            {
              icon: "mdi mdi-arrow-right",
              title: "Registration",
                to: "/service/Registration",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Review",
                to: "/service/error",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Contracts",
                to: "/service/error",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Database",
                to: "/service/error",
            },
            {
              icon: "mdi mdi-arrow-right",
              title: "Report",
                to: "/service/error",
            },
          ],
        },
        {
          id: "9",
          icon: "help-circle",
          title: "Support",
          i18n: "Support",
          to: "/test_calendar/",
        },
        {
          id: "10",
          icon: "tool",
          title: "Terms & condition",
          i18n: "Terms & condition",
          to: "/notfound/",
        }, */

        /* {
          id: "12",
          icon: "chrome",
          title: "Manage",
          i18n: "Manage",
          name: "/Manage/",
          childern: [
            {
              icon: "mdi mdi-city",
              title: "Manage Venues",
              to: "/venues",
            },
            // {
            //   icon: "mdi mdi-account-multiple",
            //   title: "Manage Staff",

            //   to: "/manage_staff",
            // },
          ],
        }, */

        /* {
          id: "3",
          icon: "calendar",
          title: "Calendar",
          i18n: "Calendar",
          to: "/calendar",
        }, */
        /* {
          id: "6",
          icon: "calendar",
          title: " Calendar",
          i18n: " Calendar",
          name: "/ calendar/",
          childern: [
            {
              icon: "mdi mdi-book-open",
              title: "Calendar",
                to: "/calendar",
            },
            {
              icon: "mdi mdi-book-open",
              title: "New Calender",
                to: "/new_calender",
            },
          ],
        }, */
       /*  {
          id: "1",
          icon: "crosshair",
          title: "Dashboard old",
          i18n: "Dashboard old" ,
          to: "/dashboard",
        },
        {
          id: "10",
          icon: "calendar",
          title: "Calendar old",
          i18n: "Calendar old",
          to: "/calendar",
        } */
        /* {
          id: "4",
          icon: "book",
          title: " Booking and Customer",
          i18n: " Booking and Customer",
          name: "/ Booking and Customer/",
          childern: [
            {
              icon: "mdi mdi-book-open",
              title: "My Booking",
                to: "/my-booking",
            },
            {
              icon: "mdi mdi-book-open",
              title: "Drafts booking",
                to: "/drafts-booking",
            },
            {
              icon: "mdi mdi-account-box",
              title: "My Customer",
              to: "/my-customers",
            },
          ],
        }, */

      /* {
          id: "5",
          icon: "settings",
          title: "Setting",
          i18n: "Setting",
          to: "/child_venue_setting/0",
        },
        {
          id: "6",
          icon: "globe",
          title: "Historical tab",
          i18n: "Historical tab",
          to: "/historical_tab",
        }, */
       /*   {
          id: "6",
          icon: "settings",
          title: "KYC Tab",
          i18n: "KYC Tab",
          to: "/kyc-tab",
        },
         {
          id: "7",
          icon: "settings",
          title: " File Upload",
          i18n: " File Upload",
          name: "/ fileupload/",
          childern: [
            {
              icon: "mdi mdi-book-open",
              title: "Pincode Upload",
                to: "/pincodeUpload",
            },
           
          ],
        }, 
        {
          id: "6",
          icon: "settings",
          title: "Time Shift Setting",
          i18n: "Time Shift Setting",
          to: "/venue_setting",
        },*/
        // {
        //   id: "7",
        //   icon: "copy",
        //   title: "Reports",
        //   i18n: "Reports",
        //   to: "/reports",
        // },
        // {
        //   id: "8",
        //   icon: "more-horizontal",
        //   title: "Payments and Discounts",
        //   i18n: "Payments and Discounts",
        //   name: "/Payments and Discounts/",
        //   childern: [
        //     {
        //       icon: "mdi mdi-credit-card",
        //       title: "Wallet",
        //       to: "/wallet",
        //     },
        //     {
        //       icon: "mdi mdi-plus-box-outline",
        //       title: "Expenses",

        //       to: "/expenses",
        //     },

        //     {
        //       icon: "mdi mdi-playlist-check",
        //       title: "Refund Management",

        //       to: "/refund_management",
        //     },
        //     {
        //       icon: "mdi mdi-tag-multiple",
        //       title: "Coupons & Discounts",

        //       to: "/coupons_discounts",
        //     },
        //   ],
        // },

       
    /*   ];
    } else if (this.role_type == "franchise") {
      this.routes = [
        {
          id: "1",
          icon: "crosshair",
          title: "Dashboard",
          i18n: "Dashboard",
          to: "/dashboard",
        },
        {
          id: "2",
          icon: "book",
          title: "Booking",
          i18n: "Booking",
          to: "/booking",
        },
        {
          id: "3",
          icon: "users",
          title: "Vendors",
          i18n: "Vendors",
          to: "/vendors",
        },
        {
          id: "4",
          icon: "chrome",
          title: "Venues",
          i18n: "Venues",
          to: "/venues",
        },
        {
          id: "5",
          icon: "users",
          title: "Manage Staff",
          i18n: "Manage staff",
          to: "/manage_staff",
        },
        {
          id: "6",
          icon: "bar-chart",
          title: "Manage Banks",
          i18n: "manage Banks",
          to: "/manage_banks",
        },
        {
          id: "7",
          icon: "bell",
          title: "Notification",
          i18n: "Notification",
          to: "/notification",
        },
        {
          id: "8",
          icon: "copy",
          title: "Reports",
          i18n: "Reports",
          to: "/reports",
        },
        {
          id: "9",
          icon: "credit-card",
          title: "Wallet",
          i18n: "Wallet",
          to: "/wallet",
        },
        {
          id: "10",
          icon: "calendar",
          title: "Calendar",
          i18n: "Calendar",
          to: "/calendar",
        },
        {
          id: "11",
          icon: "tag",
          title: "Coupons",
          i18n: "Coupons",
          to: "/coupons_discounts",
        },
      ];
    } */
  },
};
</script>

<style>
</style>